import React, { useState } from 'react'

import Toaster from 'components/basepaws/utils/Toaster'
import content from 'util/content'

export default function UserConfirmationToaster () {
  const headerContent = content.get('confirmUser.toast.title')
  const messageContent = content.get('confirmUser.toast.message')
  const [status, setStatus] = useState(true)

  // unfortunately this doesn't work, so I'm gonna have to add a "userConfirmedToast" ref in the components that consume this one
  // useIonViewWillEnter(() => {
  //   console.log('[UserConfirmationToaster] UserConfirmationToaster is triggered')
  // })

  return <Toaster
    status={status}
    onDidDismiss={() => setStatus(false)}
    header={headerContent}
    message={messageContent}
    position={'bottom'}
    duration={6_000}
  />
}
