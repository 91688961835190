import React from 'react'
import { connect } from 'react-redux'
import { withIonLifeCycle, IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import OrderListItem from 'components/basepaws/account/OrderListItem'
import OrderAdvertisingCard from 'components/basepaws/account/OrderAdvertisingCard'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { create } from 'ionicons/icons'
import services from 'util/services'
import content from 'util/content'

import AccountOrdersEmptyCats from './AccountOrdersEmptyCats'
import AccountOrdersEmptyKits from './AccountOrdersEmptyKits'

import styles from './AccountOrders.module.scss'

class AccountOrders extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchString: null,
      toggles: {}
    }
  }

  render () {
    const user = this.props.user

    let kits = null
    let kitsPets = { live: null, death: null }

    if (this.props.user?.pets?.length > 0 && this.props.user?.products?.length > 0) {
      kits = this.props.user.products
        .map(kit => {
          const pet = (this.props.user?.pets || []).find(pet => (pet.reports.find(_report => _report.id === kit.id)))
          if (pet) {
            pet.death_on = pet.death_on || null
            kit.pet = pet
          } else {
            kit.pet = null
          }
          return kit
        })

      kitsPets.live = kits.filter(kit => kit.pet != null).filter(kit => kit.pet?.death_on == null).sort((a, b) => a.pet.name.localeCompare(b.pet.name))
      kitsPets.death = kits.filter(kit => kit.pet != null).filter(kit => kit.pet?.death_on != null).sort((a, b) => a.pet.name.localeCompare(b.pet.name))
      let noPetsOnKit = kits.filter(kit => kit.pet == null) // kits without pets.

      kits = null
      kits = [...kitsPets.live, ...kitsPets.death, ...noPetsOnKit] // ordered kits
      kitsPets = null

      noPetsOnKit = null
    }

    return (
      <GeneralPage
        headerStyle='action-right'
        title={content.get('MY_ORDERS.HEADER.TITLE')}
        titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom"
        actionStyle='right'
        user={user}
        actions={
          <EditableIonButton
            mode="md"
            slot="end"
            color="primary"
            fill="clear"
            className={`${styles.toolbar_button} icon-end default_button`}
            onClick={() => {
              services.trackEvent({
                category: 'My Orders',
                action: 'Register a kit cta',
                label: 'Button'
              })
            }}
            routerLink="/kit/register"
          >
            <IonLabel>{content.get('MY_ORDERS.HEADER.CTA_TITLE')}</IonLabel>
            <IonIcon color="primary" icon={create} />
          </EditableIonButton>
        }
      >
        {
          (this.props.user?.pets?.length > 0)
            ? (
                (this.props.user?.products?.filter((kit) => kit.pet).length > 0)
                  ? kits
                    .map(kit => {
                      const pet = (this.props.user?.pets || []).find(pet => (pet.reports.find(petkit => petkit.id === kit.id)))

                      const lastDate = kit.history[kit.history.length - 1]?.estimated || [kit.history[kit.history.length - 1]?.time]
                      const report = {
                        id: kit.id,
                        type: kit.type[0],
                        last_updated: kit.date ? new Intl.DateTimeFormat('en-US').format(new Date(kit.date)) : null,
                        history: kit.history,
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam gravida vitae justo sagittis suscipit. Donec sollicitudin erat et lacinia cursus.',
                        estimated_report_date: lastDate,
                        url_print: kit.url,
                        url_send: kit.share,
                        url_view: kit.url,
                        isDigitalReport: kit.dynamicReport
                      }
                      return (
                        pet
                          ? <IonRow key={kit.id}>
                          <IonCol size="12">
                            <OrderListItem
                              kit={kit}
                              user={user}
                              pet={pet}
                              report={report}
                              toggleVar={this.state.toggles[kit.id]}
                              toggleFunction={(kitId) => {
                                const toggles = this.state.toggles
                                for (const kitIds in toggles) {
                                  if (kitId === kitIds) {
                                    toggles[kitId] = !toggles[kitId]
                                  } else {
                                    toggles[kitIds] = false
                                  }
                                }
                                this.setState({ toggles })
                              }} />
                          </IonCol>
                        </IonRow>
                          : null)
                    })
                  : <AccountOrdersEmptyKits />
              )
            : <AccountOrdersEmptyCats />
        }

        <IonRow>
          <IonCol size="12">
            <OrderAdvertisingCard />
          </IonCol>
        </IonRow>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountOrders))
